import { throttle } from 'lodash';

export function bnrAction() {
  if (document.querySelector('.js-bnr')) {
    const bnrEle = document.querySelector('.js-bnr');
    const bnrClose = document.querySelector('.js-bnrClose');

    window.addEventListener(
      'scroll',
      _.throttle(
        e => {
          scrollView();
          return false;
        },
        10,
        { trailing: true, leading: true }
      )
    );

    // ページ読み込み時にスクロールトップボタンがkvより下にあれば表示
    window.addEventListener('load', () => {
      const scroll = window.pageYOffset;
      if (scroll >= 115) {
        bnrEle.classList.add('is-view');
      } else {
        bnrEle.classList.remove('is-view');
      }
    });

    bnrClose.addEventListener('click', () => {
      const bnr = bnrClose.closest('.js-bnr');
      bnr.remove();
    });

    // 間引きしたい処理
    function scrollView() {
      const scroll = window.pageYOffset;

      // スクロール量がkvの高さを超えたらページトップボタンを表示
      if (scroll >= 115) {
        bnrEle.classList.add('is-view');
      } else {
        bnrEle.classList.remove('is-view');
      }
    }
  }
}
